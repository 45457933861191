import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getOrders,
  getSpecificOrder,
  setOrderRefund
} from "../../store/actions/orders.actions";
// import _ from "lodash";
import moment from "moment";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Location from "../../components/location/location";
import {
  orderRefundReasons,
  orderType,
  paymentMethods,
  statuses
} from "./constants";
import { orderStatus } from "../orders";
import "./index.scss";
import { resetError } from "../../store/actions/points.actions";
import { ToastContainer, toast } from "react-toastify";

const PAYMENT_FAILED = 5;
const FOODICS_FAILED = 6;
const DELIVERY = 4;

const OrderDetailsPage = ({ match }) => {
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const dispatch = useDispatch();
  const orders = useSelector(state => state.orders.orders);
  const createOrderRefundError = useSelector(
    state => state.orders.createOrderRefundError
  );
  // const specificOrder = useSelector(state => state.orders.specificOrder);
  const loadingOrders = useSelector(state => state.orders.loadingOrders);

  const [currentOrder, setCurrentOrder] = useState(undefined);

  useEffect(() => {
    if (orders.length > 0) {
      const order = orders.find(o => o.id === match.params.id);
      setCurrentOrder(order);
    }
  }, [orders, match.params.id]);

  const formik = useFormik({
    initialValues: {
      amount: 0,
      orderId: match.params.id,
      reason: "",
      reasonDescription: "",
      paymentMethod: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .required("Required")
        .min(1, "Must be at least 1 riyal")
        .max(
          currentOrder && currentOrder.finalPrice,
          "Refund Amount can't be more than paid amount"
        )
        .positive(),
      orderId: Yup.string().required("Required"),
      reason: Yup.string().required("Required"),
      reasonDescription: Yup.string().required("Required"),
      paymentMethod: Yup.string().required("Required")
    }),
    onSubmit: values => {
      dispatch(setOrderRefund(values));
      formik.resetForm();
      setTimeout(() => {
        dispatch(resetError());
      }, 4000); // TODO: to reduce time needed for the create order
    }
  });
  useEffect(() => {
    dispatch(getOrders(match.params.Hid));
    dispatch(getSpecificOrder(match.params.id));
  }, [match.params.Hid]);

  useEffect(() => {
    if (createOrderRefundError) {
      toast.error(createOrderRefundError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }, [createOrderRefundError]);

  const getError = name =>
    formik.errors[name] && formik.touched[name] ? (
      <ul className="parsley-errors-list filled">
        <li className="parsley-required">{formik.errors[name]}</li>
      </ul>
    ) : null;

  function displayPaymentMethod(payments) {
    const { paidOnline, paidWithPoints, paidWithWallet } = payments;
    let paymentMethods = [];

    if (paidOnline > 0) paymentMethods.push("Online");
    if (paidWithPoints > 0) paymentMethods.push("Points");
    if (paidWithWallet > 0) paymentMethods.push("Wallet");
    if (paymentMethods.length === 0) paymentMethods.push("Cash");

    return paymentMethods.join(", ");
  }

  const paymentType = id => {
    switch (id) {
      case "_671g2647":
        return "Card";
      case "_74gag247":
        return "Apple Pay";
      case "9ae2bf9c-1cee-460c-a983-30a03d147949":
        return "Wallet";
      case "_741968g7": // this payment method was missing
        return "Points";
      default:
        return "UNKNOWN";
    }
  };

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Order Details</h4>
              </div>
            </div>
            {loadingOrders ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="card-body">
                      <div className="col-lg-12 h-50vh">
                        <div className="card-box h-100">
                          {orders.map(
                            order =>
                              order.id === match.params.id && (
                                <div key={order.id}>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Order Id
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Order Id"
                                      value={order.id}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Foodics Id
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Foodics Id"
                                      value={order.foodicsId || ""}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Created at
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder=" Created at"
                                      value={moment
                                        .utc(order.createdAt)
                                        .local()
                                        .format("YYYY-MM-DD hh:mm:ss")}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Scheduled at
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Scheduled at"
                                      value={moment
                                        .utc(order.scheduledAt)
                                        .local()
                                        .format("YYYY-MM-DD hh:mm:ss")}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      To be Initialized Time
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="To be Initialized Time"
                                      value={order.orderToBeInitialized}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Due Time
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Due Time"
                                      value={moment
                                        .utc(order.dueTime)
                                        .local()
                                        .format("YYYY-MM-DD hh:mm:ss")}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      User Device
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="User Device"
                                      value={
                                        order.tags &&
                                        order.tags.length > 1 &&
                                        order.tags[0]
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Payment Type
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Payment Type"
                                      value={
                                        !order.payments
                                          ? "Cash on Delivery"
                                          : order.payments.payments &&
                                            order.payments.payments.length >
                                              0 &&
                                            order.payments.payments.map(
                                              payment =>
                                                paymentType(
                                                  payment.paymentMethodHid
                                                )
                                            )
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Order Date
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Order Date"
                                      value={moment
                                        .utc(order.orderDate)
                                        .local()
                                        .format("YYYY-MM-DD hh:mm:ss")}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Order Status
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Order Status"
                                      value={orderStatus[order.status]}
                                      // value={
                                      //   _.isEmpty(specificOrder.dashboardStatuses)
                                      //     ? ""
                                      //     : specificOrder.dashboardStatuses[
                                      //         specificOrder.dashboardStatuses
                                      //           .length - 1
                                      //       ].nameEn
                                      // }
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Order Type
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Order Type"
                                      value={orderType[order.type]}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Order Notes
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Order Notes"
                                      value={order.notes}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Order Final Price
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Order Final Price"
                                      value={order.finalPrice}
                                      required
                                    />
                                  </div>
                                  <div className="form-group mb-1">
                                    <label htmlFor="product-meta-1">
                                      Earned Points
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      placeholder="Order Final Price"
                                      value={order.assignedPoints}
                                      required
                                    />
                                  </div>
                                  {order.type === DELIVERY && (
                                    <div className="orderMap">
                                      <label htmlFor="product-meta-1">
                                        Address
                                      </label>
                                      <div>
                                        <Location
                                          location={{
                                            lat:
                                              order.address &&
                                              order.address.lat,
                                            lng:
                                              order.address &&
                                              order.address.long
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="table-responsive mt-2">
                                    <table
                                      id="demo-foo-filtering"
                                      className="table table-centered"
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th>#</th>
                                          <th>Description</th>
                                          <th>Product Hid</th>
                                          <th>Size Hid</th>
                                          <th>Modifier name</th>
                                          <th>Modifier Hid</th>
                                          <th>Quantity</th>
                                          <th>Price</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {order.products &&
                                          order.products.map(
                                            (product, index) => (
                                              <tr key={product.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  <div>
                                                    <div>#{product.guid}</div>
                                                    <dir>
                                                      {product.productSize &&
                                                        product.productSize.name
                                                          .en}
                                                    </dir>
                                                    <div>
                                                      {product.name &&
                                                        product.name.en}
                                                    </div>
                                                  </div>

                                                  <div>
                                                    Notes: {product.notes}
                                                  </div>
                                                </td>
                                                <td>{product.foodicsId}</td>
                                                <td>
                                                  {product.productSizeHid}
                                                </td>
                                                <td>
                                                  {product.options.map(
                                                    (i, index) => (
                                                      <div key={index}>
                                                        {i.name.en}
                                                      </div>
                                                    )
                                                  )}
                                                </td>
                                                <td>
                                                  {product.options.map(
                                                    (i, index) => (
                                                      <div key={index}>
                                                        {i.foodicsId}
                                                      </div>
                                                    )
                                                  )}
                                                </td>
                                                <td>{product.quantity}</td>
                                                <td>{product.originalPrice}</td>
                                              </tr>
                                            )
                                          )}
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>Used Points</td>
                                          <td>
                                            {order.payments.paidWithPoints || 0}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>Discount</td>
                                          <td>{order.discountAmount}</td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>Delivery Price</td>
                                          <td>{order.deliveryPrice}</td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>Final price</td>
                                          <td>{order.finalPrice}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                  {order.payments && (
                                    <div className="table-responsive mt-2">
                                      <table
                                        id="demo-foo-filtering"
                                        className="table table-centered text-center"
                                      >
                                        <thead className="thead-light">
                                          <tr>
                                            <th>Amount</th>
                                            <th>Payment Method Hid</th>
                                            <th>Date</th>
                                            <th>Transaction Id</th>
                                            <th>Transaction Status</th>
                                            <th>Payment Method</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {order.payments &&
                                            order.payments.payments &&
                                            order.payments.payments.length >
                                              0 &&
                                            order.payments.payments.map(
                                              (p, index) => (
                                                <tr key={index}>
                                                  <td>{p.amount}</td>
                                                  <td>{p.paymentMethodHid} </td>
                                                  <td>{p.actualDate}</td>
                                                  <td>{p.transactionId}</td>
                                                  <td>
                                                    {
                                                      statuses[
                                                        p.transactionStatus
                                                      ]
                                                    }
                                                  </td>
                                                  <td className="text-center">
                                                    {!order.payments
                                                      ? "Cash on Delivery"
                                                      : order.payments
                                                          .payments &&
                                                        paymentType(
                                                          p.paymentMethodHid
                                                        )}{" "}
                                                    - ({" "}
                                                    {order.payments &&
                                                      displayPaymentMethod(
                                                        order.payments
                                                      )}
                                                    )
                                                  </td>
                                                </tr>
                                              )
                                            )}

                                          {/* if there's no payment, it's defiantly cash on delivery */}
                                          {order.payments &&
                                            order.payments.payments &&
                                            order.payments.payments.length ==
                                              0 && (
                                              <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-center">
                                                  {!order.payments.payments
                                                    .length
                                                    ? "Cash on Delivery"
                                                    : ""}{" "}
                                                  - ({" "}
                                                  {order.payments &&
                                                    displayPaymentMethod(
                                                      order.payments
                                                    )}
                                                  )
                                                </td>
                                              </tr>
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                  {order.status === PAYMENT_FAILED ||
                                  order.status === FOODICS_FAILED ? (
                                    <div className="form-group mb-1">
                                      <label htmlFor="product-meta-1">
                                        Error
                                      </label>
                                      <textarea
                                        disabled
                                        className="form-control"
                                        id="error"
                                        placeholder="Order error"
                                        value={
                                          (order.error &&
                                            Object.entries(order.error).map(
                                              item => `${item[0]} : ${item[1]}`
                                            )) ||
                                          ""
                                        }
                                      ></textarea>
                                    </div>
                                  ) : (
                                    <div className="form-group mb-1">
                                      <label htmlFor="product-meta-1">
                                        Foodics Response
                                      </label>
                                      <textarea
                                        disabled
                                        className="form-control"
                                        id="foodicsResponse"
                                        placeholder=" Foodics Response"
                                        value={
                                          (order.foodicsResponse &&
                                            Object.entries(
                                              order.foodicsResponse
                                            ).map(item => {
                                              return `${
                                                item[0]
                                              } : ${JSON.stringify(
                                                item[1],
                                                null,
                                                2
                                              )}`;
                                            })) ||
                                          ""
                                        }
                                      ></textarea>
                                    </div>
                                  )}
                                  <div className="form-group my-2">
                                    <label htmlFor="product-meta-1">
                                      Order Error
                                    </label>
                                    <textarea
                                      disabled
                                      className="form-control"
                                      id="error"
                                      placeholder=" Order Error"
                                      value={
                                        (order.error &&
                                          Object.entries(order.error).map(
                                            item => {
                                              return `${
                                                item[0]
                                              } : ${JSON.stringify(
                                                item[1],
                                                null,
                                                2
                                              )}`;
                                            }
                                          )) ||
                                        ""
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card-body">
                      <div className="col-lg-12 h-50vh">
                        <div className="card-box h-100">
                          <div className="form-group mb-1">
                            <label>Previous Refunds</label>
                            {currentOrder &&
                              currentOrder.refunds &&
                              currentOrder.refunds.length &&
                              currentOrder.refunds.map(or => (
                                <div className="row pl-2" key={or.id}>
                                  <h6 className="mx-1 small">
                                    Success: {or.success.toString()}{" "}
                                    <span
                                      className={`d-inline-block rounded-circle p-1 bg-${
                                        or.success ? "success" : "danger"
                                      }`}
                                    ></span>
                                  </h6>
                                  <h6 className="mx-1 small">
                                    Amount: {or.amount}
                                  </h6>
                                  <h6 className="mx-1 small">
                                    Supervisor:{" "}
                                    {or.admin ? or.admin.name : or.admin_id}
                                  </h6>
                                  <h6 className="mx-1 small">
                                    Main reason: {or.reason}
                                  </h6>
                                  <br />
                                  <h6 className="mx-1 small">
                                    Description: {or.reasonDescription}
                                  </h6>
                                  <h6 className="mx-1 small">
                                    Created at: {or.createdAt}
                                  </h6>
                                </div>
                              ))}
                            {currentOrder &&
                              !currentOrder.refunds &&
                              !currentOrder.refunds.length && (
                                <h4>No previous refunds</h4>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {currentOrder &&
                currentOrder.payments &&
                paymentType(currentOrder.payments.paymentMethodHid) !=
                  "UNKNOWN" ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="card-body">
                        <div className="col-lg-12 h-50vh">
                          <div className="card-box h-100">
                            <div className="form-group mb-1">
                              <form onSubmit={formik.handleSubmit}>
                                <>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <label htmlFor="order-refund-meta-1 ">
                                        Refund amount
                                      </label>
                                    </div>
                                    <div className="col-lg-4 mx-2">
                                      <label htmlFor="reason">
                                        Main Reason:
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <input
                                      id="order-refund-meta-1"
                                      name="amount"
                                      type="number"
                                      className="form-control col-lg-2"
                                      placeholder="Order Refund amount"
                                      onChange={formik.handleChange}
                                      value={formik.values.amount}
                                      max={
                                        currentOrder.finalPrice
                                          ? Number(currentOrder.finalPrice)
                                          : ""
                                      }
                                      required
                                    />
                                    <input
                                      type="text"
                                      name="currency"
                                      id=""
                                      value={"S.R"}
                                      className="col-lg-1"
                                      disabled
                                    />
                                    <p>{getError("amount")}</p>

                                    <select
                                      required
                                      id="reason"
                                      name="reason"
                                      className="form-control col-lg-4 mx-2"
                                      onChange={formik.handleChange}
                                      value={formik.values.reason}
                                    >
                                      {orderRefundReasons.map(reason => (
                                        <option
                                          key={reason.name}
                                          value={`${reason.value}`}
                                        >
                                          {reason.name}
                                        </option>
                                      ))}
                                    </select>
                                    <p>{getError("reason")}</p>
                                  </div>
                                  <div className="row mt-4">
                                    <div className="col-lg-4">
                                      <label htmlFor="paymentMethod">
                                        Refund Methods:
                                      </label>
                                    </div>
                                    <div className="col-lg-4">
                                      <label htmlFor="reasonDescription">
                                        Reason Description
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row ">
                                    <div className="col-lg-4">
                                      <select
                                        required
                                        id="paymentMethod"
                                        name="paymentMethod"
                                        className="form-control "
                                        onChange={formik.handleChange}
                                        value={formik.values.paymentMethod}
                                      >
                                        {paymentMethods.map(method => (
                                          <option
                                            key={method.name}
                                            value={`${method.value}`}
                                          >
                                            {method.name}
                                          </option>
                                        ))}
                                      </select>
                                      <p>{getError("paymentMethod")}</p>
                                    </div>

                                    <div className="clo-lg-4">
                                      <textarea
                                        // rows="5"
                                        cols={"20"}
                                        name="reasonDescription"
                                        id="reasonDescription"
                                        className={classNames("form-control ")}
                                        placeholder={"Reason description"}
                                        onChange={formik.handleChange}
                                        value={
                                          formik.values.reasonDescription || ""
                                        }
                                      ></textarea>
                                      <p>{getError("reasonDescription")}</p>
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    <button
                                      className="form-control col-lg-2  bg-success text-white"
                                      color="secondary"
                                      type="submit"
                                    >
                                      Refund
                                    </button>
                                  </div>
                                </>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}

            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPage;
